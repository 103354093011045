function processResponse(response, filename) {
  const internalUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const contentDisposition = response.headers['content-disposition'];
  let fileName = filename || 'unknown';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch && fileNameMatch.length === 2)
      fileName = fileNameMatch[1];
  }
  link.href = internalUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export default {
  methods: {
    downloadFile(
        url,
        method = null,
        params = null,
        filename = null,
        mimeType = null,
        callback = null,
        callback_error = null,
        setAcceptHeader = false,
    ) {
      var that = this;
      method = (method || 'get').toLowerCase();
      params = params || {};
      filename = filename || "download.pdf";
      mimeType = mimeType || 'application/pdf';
      if (method === 'get') {
        params = {
          responseType: "blob",
          params: params
        };
        if (setAcceptHeader)
          params.headers = { 'Accept': mimeType }
        this.$http
          .get(url, params)
          .then(response => {
            processResponse(response, filename);
          })
          .catch((error) => {
            if (callback_error) callback_error(error);
          })
          .finally(function () {
            if (callback) callback();
          });
      } else {
        this.$http[method](url, params, {
          headers: { 'Accept': mimeType },
          responseType: 'blob',
        })
        .then(response => {
          processResponse(response, filename);
        })
        .catch(error => {
          if (callback_error) callback_error(error);
        })
        .finally(function () {
          if (callback) callback()
        });
      }
    }
  }
}