<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("open invoices") }}</v-toolbar-title>
        <v-spacer />
        <wiki slug="invoice-overview" />
      </v-toolbar>
      <data-table-extended
        :headers="headersOpen"
        :items="openInvoices"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="number"
        sort-desc
        :hide-default-footer="openInvoices.length <= 15"
        :footer-props="{
          itemsPerPageOptions: [15, 50, -1],
        }"
        single-expand
        show-expand
        @item-expanded="
          invoice = null;
          getInvoice($event);
        "
        open-expand-by-dbl-click-row
        local-storage-name="invoiceListOpen"
      >
        <template v-slot:item.create_date="{ item }">
          <div class="monospace">
            {{ item.create_date != null ? formatDate(item.create_date) : "" }}
          </div>
        </template>

        <template v-slot:item.total_amount="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.total_amount != null
                ? formatMoney(item.total_amount, item.currency)
                : ""
            }}
          </div>
        </template>

        <template v-slot:item.open_amount="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.open_amount != null
                ? formatMoney(item.open_amount, item.currency)
                : ""
            }}
          </div>
        </template>

        <template v-slot:item.due_date="{ item }">
          <div class="monospace">
            {{ item.due_date != null ? formatDate(item.due_date) : "" }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="text-no-wrap">
            <v-tooltip v-if="item.pdf_available" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="downloadFile('invoices/' + item.id + '/pdf')"
                  text
                  small
                  icon
                >
                  <v-icon>$PdfDownload</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("show invoice PDF") }}</span>
            </v-tooltip>
            <v-icon v-else :style="{ visibility: 'hidden' }">mdi-minus</v-icon>

            <v-tooltip v-if="!item.paid && showPaymentLink" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :href="item.url"
                  target="_blank"
                  text
                  small
                  icon
                >
                  <v-icon>mdi-currency-usd</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("pay invoice") }}</span>
            </v-tooltip>
            <v-icon v-else :style="{ visibility: 'hidden' }">mdi-minus</v-icon>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    $router.push({
                      name: 'invoice-details',
                      params: { invoiceId: item.id },
                    })
                  "
                  text
                  small
                  icon
                >
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("open details") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <invoice-overview :invoice="invoice || item" class="my-2" />
          </td>
        </template>
      </data-table-extended>
    </v-card>

    <v-card>
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("paid invoices") }}</v-toolbar-title>
        <v-spacer />
        <wiki slug="invoice-overview" />
      </v-toolbar>
      <data-table-extended
        :headers="headers"
        :items="paidInvoices"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="number"
        sort-desc
        :hide-default-footer="paidInvoices.length <= 15"
        :footer-props="{
          itemsPerPageOptions: [15, 50, -1],
        }"
        local-storage-name="invoiceListPayed"
      >
        <template v-slot:item.create_date="{ item }">
          <div class="monospace">
            {{ item.create_date != null ? formatDate(item.create_date) : "" }}
          </div>
        </template>

        <template v-slot:item.total_amount="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.total_amount != null
                ? formatMoney(item.total_amount, item.currency)
                : ""
            }}
          </div>
        </template>

        <template v-slot:item.due_date="{ item }">
          <div class="monospace">
            {{ item.due_date != null ? formatDate(item.due_date) : "" }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="text-no-wrap">
            <v-tooltip v-if="item.pdf_available" top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="downloadFile('invoices/' + item.id + '/pdf')"
                  text
                  small
                  icon
                >
                  <v-icon>$PdfDownload</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("show invoice PDF") }}</span>
            </v-tooltip>
            <v-icon v-else :style="{ visibility: 'hidden' }">mdi-minus</v-icon>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    $router.push({
                      name: 'invoice-details',
                      params: { invoiceId: item.id },
                    })
                  "
                  text
                  small
                  icon
                >
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("open details") }}</span>
            </v-tooltip>
          </div>
        </template>
      </data-table-extended>
    </v-card>
  </div>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended.vue";
import formatMoney from "@/utils/mixins/formatMoney";
import formatDate from "@/utils/mixins/formatDate";
import downloadFile from "@/utils/mixins/downloadFile";
import isMobile from "@/utils/mixins/isMobile";
import InvoiceOverview from "@/components/invoices/InvoiceOverview";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "InvoiceList",
  components: {
    DataTableExtended,
    InvoiceOverview,
    Wiki,
  },
  mixins: [formatMoney, formatDate, downloadFile, isMobile],
  data: () => ({
    paidInvoices: [],
    openInvoices: [],
    invoice: null,
    loading: false,
    loadingInvoice: false,
  }),
  computed: {
    showPaymentLink() {
      return this.$config.mandant == "iway";
    },
    headers() {
      return [
        {
          text: this.$i18n.t("nr."),
          value: "number",
        },
        {
          text: this.$i18n.t("create date"),
          value: "create_date",
          align: "right",
        },
        {
          text: this.$i18n.t("title"),
          value: "description",
        },
        {
          text: this.$i18n.t("due date"),
          align: "right",
          value: "due_date",
        },
        {
          text: this.$i18n.t("amount"),
          align: "right",
          value: "total_amount",
        },
        {
          text: this.$i18n.t("commission code"),
          value: "commission_code",
          hidden: true,
        },
        {
          text: this.$i18n.t("commission descr"),
          value: "commission_descr",
          hidden: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ];
    },
    headersOpen() {
      return [
        {
          text: this.$i18n.t("nr."),
          value: "number",
        },
        {
          text: this.$i18n.t("create date"),
          value: "create_date",
          align: "right",
        },
        {
          text: this.$i18n.t("title"),
          value: "description",
        },
        {
          text: this.$i18n.t("due date"),
          align: "right",
          value: "due_date",
        },
        {
          text: this.$i18n.t("amount"),
          align: "right",
          value: "total_amount",
        },
        {
          text: this.$i18n.t("open"),
          align: "right",
          value: "open_amount",
        },
        {
          text: this.$i18n.t("commission code"),
          value: "commission_code",
          hidden: true,
        },
        {
          text: this.$i18n.t("commission descr"),
          value: "commission_descr",
          hidden: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.invoice = null;
      this.paidInvoices = [];
      this.openInvoices = [];
      this.getInvoices();
    },
  },
  methods: {
    getInvoices() {
      var that = this;
      that.loading = true;
      this.$http
        .get("invoices")
        .then((response) => {
          var invoices = response.data;
          that.openInvoices = response.data.filter(function (item) {
            return item.paid == false;
          });
          that.paidInvoices = response.data.filter(function (item) {
            return item.paid == true;
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getInvoice(event) {
      if (event.value) {
        var that = this;
        that.loadingInvoice = true;
        that.$http
          .get("invoices/" + event.item.id)
          .then((response) => {
            that.invoice = response.data;
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loadingInvoice = false;
          });
      } else this.invoice = null;
    },
  },
  mounted() {
    this.getInvoices();
  },
};
</script>

<i18n>
{
  "en": {
    "open invoices": "Open invoices",
    "paid invoices": "Paid invoices",
    "create date": "Create date",
    "due date": "Due date",
    "amount": "Amount",
    "open": "Open",
    "show invoice PDF": "Show invoice PDF",
    "pay invoice": "Pay invoice",
    "open details": "Open details",
    "commission code": "Commission code",
    "commission descr": "Commission description"
  },
  "de": {
    "open invoices": "offene Rechnungen",
    "paid invoices": "bezahlte Rechnungen",
    "create date": "Erstellungsdatum",
    "due date": "Fälligkeitsdatum",
    "amount": "Betrag",
    "open": "Offen",
    "show invoice PDF": "zeige Rechnungs PDF",
    "pay invoice": "bezahle Rechnung",
    "open details": "öffne Details",
    "commission code": "Kommission Code",
    "commission descr": "Kommission Beschr."
  }
}
</i18n>
