import store from "@/store";

export default {
  methods: {
    copyToClipboard(elementID, successMessage = null) {
      var that = this;
      var copyText = document.getElementById(elementID);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      if (successMessage != null)
        that.$snotify.success(successMessage, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
    },
    copyTextToClipboard(textToCopy, successMessage = null) {
      var that = this;
      var TempText = document.createElement("input");
      TempText.value = textToCopy;
      document.body.appendChild(TempText);
      TempText.select();
      document.execCommand("copy");
      document.body.removeChild(TempText);

      if (successMessage != null)
        that.$snotify.success(successMessage, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
    },
    copyTextToClipboardWithNewLines(textToCopy, successMessage = null) {
      var that = this;
      var TempText = document.createElement("textarea");
      TempText.value = textToCopy;
      document.body.appendChild(TempText);
      TempText.select();
      document.execCommand("copy");
      document.body.removeChild(TempText);

      if (successMessage != null)
        that.$snotify.success(successMessage, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
    },
  },
};
