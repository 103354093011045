<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :headers="visibleHeaders"
      :show-expand="showExpand"
      @click:row="
        (item, slot) =>
          openExpandByClickRow ? slot.expand(!slot.isExpanded) : null
      "
      @dblclick:row="
        (item, slot) =>
          openExpandByDblClickRow ? slot.expand(!slot.isExpanded) : null
      "
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot :name="slot" v-bind="scope" />
      </template>
      <!-- add menu to action header slots -->
      <template
        v-slot:header.action="{ item }"
        v-if="showMenu && menuSlot == 'action'"
      >
        <v-spacer />
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" :loading="headerLoading">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list nav dense v-if="showHeaderMenu">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  <v-subheader>{{ $t("visible columns") }}</v-subheader>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <template v-for="(col, i) in headers || []">
              <v-list-item
                v-if="col.text"
                :key="i"
                @click="updateHeaders(col)"
                dense
              >
                <v-list-item-icon>
                  <v-icon v-if="(hiddenHeaders || {})[col.value]" small>
                    mdi-eye-off
                  </v-icon>
                  <v-icon v-else small>mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ col.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <slot name="header.action.expand" />
        </v-menu>
      </template>
      <template
        v-slot:header.data-table-expand="{ item }"
        v-else-if="showMenu && menuSlot == 'expand'"
      >
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense v-if="showHeaderMenu">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  <v-subheader>{{ $t("visible columns") }}</v-subheader>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <template v-for="(col, i) in headers || []">
              <v-list-item
                v-if="col.text"
                :key="i"
                @click="updateHeaders(col)"
                dense
              >
                <v-list-item-icon>
                  <v-icon v-if="(hiddenHeaders || {})[col.value]" dense>
                    mdi-eye-off
                  </v-icon>
                  <v-icon v-else dense>mdi-eye</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ col.text }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
          <slot name="header.action.expand" />
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "DataTableExtended",
  props: {
    showExpand: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    showHeaderMenu: {
      type: Boolean,
      default: true,
    },
    headerLoading: {
      type: Boolean,
      default: false,
    },
    localStorageName: {
      type: String,
      default: null,
    },
    openExpandByClickRow: {
      type: Boolean,
      default: false,
    },
    openExpandByDblClickRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hiddenHeaders: {},
      defaultHiddenHeaders: [],
      localStoragePrefix: "menuHiddenHeaders",
    };
  },
  computed: {
    visibleHeaders() {
      var that = this;
      return (this.headers || []).filter((e) => !that.hiddenHeaders[e.value]);
    },
    menuSlot() {
      if (
        (this.headers || []).filter(function (e) {
          return e.value == "action";
        }).length > 0
      )
        return "action";
      else if (this.showExpand) return "expand";
      return "action";
    },
  },
  watch: {
    hiddenHeaders() {
      if (this.localStorageName)
        localStorage.setItem(
          this.localStoragePrefix + ":" + this.localStorageName,
          JSON.stringify(this.hiddenHeaders)
        );
    },
    defaultHiddenHeaders() {
      var that = this;
      var hiddenHeaders = {};
      this.defaultHiddenHeaders.forEach(function (name) {
        if (that.hiddenHeaders[name] == null) hiddenHeaders[name] = true;
      });
      this.hiddenHeaders = {
        ...hiddenHeaders,
        ...this.hiddenHeaders,
      };
    },
    headers: {
      immediate: true,
      handler(headers) {
        if (headers)
          this.defaultHiddenHeaders = headers
            .filter(function (e) {
              return e.hidden || false;
            })
            .map(function (e) {
              return e.value;
            });
      },
    },
  },
  mounted() {
    if (
      this.localStorageName &&
      localStorage.getItem(
        this.localStoragePrefix + ":" + this.localStorageName
      )
    ) {
      var hiddenHeaders = JSON.parse(
        localStorage.getItem(
          this.localStoragePrefix + ":" + this.localStorageName
        )
      );
      this.hiddenHeaders = {
        ...this.hiddenHeaders,
        ...hiddenHeaders,
      };
    }
  },
  methods: {
    updateHeaders(col) {
      var hiddenHeaders = { ...this.hiddenHeaders };
      if (hiddenHeaders[col.value] == null) hiddenHeaders[col.value] = true;
      else hiddenHeaders[col.value] = !hiddenHeaders[col.value];
      this.hiddenHeaders = hiddenHeaders;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "visible columns": "Visible columns"
  },
  "de": {
    "visible columns": "Sichtbare Spalten"
  }
}
</i18n>
