<template>
  <div class="my-4">
    <v-row align="stretch" align-content="center" justify="center" no-glutters>
      <v-col cols="3" v-if="!invoice.paid && qrImage != null">
        <v-img :src="qrImage" :alt="$t('alt')" contain />
        <v-btn
          color="primary"
          @click="copyTextToClipboardWithNewLines(invoice.qr_code)"
          class="ma-2"
          x-small
          text
        >
          <v-icon left>mdi-content-copy</v-icon>
          {{ $t("copy QR code data") }}
        </v-btn>
      </v-col>
      <v-col :cols="!invoice.paid && qrImage != null ? 9 : 12">
        <v-simple-table dense>
          <tbody>
            <tr>
              <th>{{ $t("invoice number") }}</th>
              <td>{{ invoice.number }}</td>
            </tr>
            <tr>
              <th>{{ $t("create date") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatDate(invoice.create_date) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("due date") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatDate(invoice.due_date) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("net amount") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatMoney(invoice.amount, invoice.currency) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("VAT amount") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatMoney(invoice.vat, invoice.currency) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("total amount") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatMoney(invoice.total_amount, invoice.currency) }}
              </td>
            </tr>
            <tr v-if="invoice.open_amount">
              <th>{{ $t("open amount") }}</th>
              <td class="monospace text-no-wrap">
                {{ formatMoney(invoice.open_amount, invoice.currency) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("description") }}</th>
              <td>{{ invoice.description }}</td>
            </tr>
            <tr v-if="invoice.commission_code != null">
              <th>{{ $t("commission code") }}</th>
              <td>{{ invoice.commission_code }}</td>
            </tr>
            <tr v-if="invoice.commission_descr != null">
              <th>{{ $t("commission descr") }}</th>
              <td>{{ invoice.commission_descr }}</td>
            </tr>
            <tr>
              <th>{{ $t("reference number") }}</th>
              <td>{{ invoice.reference_number_formatted }}</td>
            </tr>
            <tr v-if="invoice.qr_reference != null">
              <th>{{ $t("QR reference") }}</th>
              <td>
                {{ invoice.qr_reference }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";
import formatDate from "@/utils/mixins/formatDate";
import copyToClipboard from "@/utils/mixins/copyToClipboard";

export default {
  name: "InvoiceOverview",
  mixins: [formatMoney, formatDate, copyToClipboard],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    qrImage: null,
  }),
  watch: {
    invoice: {
      immediate: true,
      handler() {
        this.qrImage = null;
        this.getQrImage();
      },
    },
  },
  methods: {
    getQrImage() {
      var that = this;
      if (this.invoice && !this.invoice.paid) {
        that.$http
          .get("invoices/" + this.invoice.id + "?format=qrcode", {
            responseType: "arraybuffer",
            headers: {
              Accept: "image/png",
            },
          })
          .then((response) => {
            // convert binary to base64
            var base64String = btoa(
              String.fromCharCode.apply(null, new Uint8Array(response.data))
            );
            that.qrImage = "data:image/png;base64," + base64String;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "alt": "QR Bill",
    "invoice number": "Invoice number",
    "create date": "Create date",
    "due date": "Due date",
    "amount": "Amount",
    "net amount": "Net amount",
    "total amount": "Total amount",
    "open amount": "Open amount",
    "VAT amount": "VAT amount",
    "description": "Description",
    "reference number": "Reference number",
    "QR reference": "QR reference",
    "commission code": "Commission code",
    "commission descr": "Commission description",
    "copy QR code data": "copy QR code data"
  },
  "de": {
    "alt": "QR Rechnung",
    "invoice number": "Rechnungsnummer",
    "create date": "Erstellungsdatum",
    "due date": "Fälligkeitsdatum",
    "amount": "Betrag",
    "net amount": "Netto Betrag",
    "total amount": "Betrag inkl. MwSt.",
    "open amount": "Betrag offen",
    "VAT amount": "MwSt. Betrag",
    "description": "Beschreibung",
    "reference number": "Referenz Nummer",
    "QR reference": "QR Referenz",
    "commission code": "Kommission Code",
    "commission descr": "Kommission Beschr.",
    "copy QR code data": "QR Code Daten kopieren"
  }
}
</i18n>
